import React from 'react';
import { connect } from 'react-redux';
import { PPMSSnippet } from '@piwikpro/ui-components';

export class PPMSSnippetPanel extends React.Component<any> {
  render() {
    const { jwt, userRole } = this.props;

    if (!jwt || !userRole) return null;

    return (
      <PPMSSnippet
        jwt={jwt}
        userRole={userRole}
      />
    );
  }
}

const mapStateToProps = state => ({
  jwt: state.session.jwt,
  userRole: state.session.userRole,
});

export default connect(mapStateToProps, null)(PPMSSnippetPanel);
