import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Preloader, SplashScreen, Root } from '@piwikpro/ui-components';
import { withInject } from '@piwikpro/platform';
import DefaultModuleRedirector from './DefaultModuleRedirector';

const PPMS = ({
  config,
  Secured,
  Administration,
  UserPanel,
  history,
  Info,
}) => (
  <Secured>
    <ConnectedRouter history={history}>
      <Suspense fallback={<Preloader />}>
        <Switch>
          <Route exact path="/">
            <DefaultModuleRedirector />
          </Route>
          <Route path={config.get('USER_PANEL_BASE_URL')}>
            <UserPanel />
          </Route>
          <Route
            path={config.get('ADMINISTRATION_BASE_URL')}
          >
            <Administration />
          </Route>
          <Route path={config.get('INFO_BASE_URL')}>
            <Info />
          </Route>
          <Route path="*">
            <Root>
              <SplashScreen type="notFound" />
            </Root>
          </Route>
        </Switch>
      </Suspense>
    </ConnectedRouter>
  </Secured>
);


export default withInject({
  config: 'config',
  history: 'RouterCrate.history',
  Secured: 'AuthCrate.components.Secured',
  Administration: 'AdministrationCrate.components.Administration',
  UserPanel: 'UserPanelCrate.components.UserPanel',
  Info: 'InfoCrate.components.Info',
})(PPMS);
